@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick.min.css');
@import url('https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick-theme.min.css');

@layer base {
    html {
        @apply antialiased overflow-x-hidden  
    }

    body {
        font-family: 'Mukta', sans-serif !important;
        @apply antialiased bg-secondary pt-20 w-screen
    }
    #root {
        @apply flex flex-col justify-between min-h-screen
    }
}

.slick-slider {
    @apply h-screen
}
.wb-break-words {
    word-break: break-word;
}

    input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
border: 2px solid border-color;
-webkit-text-fill-color: white;
transition: background-color 5000s ease-in-out 0s;
color: white;
}

.animation-pulse {
    /*box-shadow: 0 0 0 20px rgba(229,62,62, 0.5);
    transform: scale(0.8);*/
    animation: pulse 2s infinite;
}
@keyframes pulse {
    0% {
        transform: scale(0.8);
        box-shadow: 0 0 0 0 rgba(1, 252, 161, 1);
    }

    70% {
        transform: scale(1);
        box-shadow: 0 0 0 10px rgba(1, 252, 161, 0);
    }

    100% {
        transform: scale(0.8);
    }
}
